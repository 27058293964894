import React from "react";

const FourZeroFourPage = () => {
  return (
    <>
      <h1 className="text-center margin-2rem">404</h1>;
      <h4 className="text-center">Hakemaasi sivua ei löytynyt</h4>;
    </>
  );
};

export default FourZeroFourPage;
